<template>
  <div class="archives-search">
    <v-text-field
      v-model="searchFields.company"
      label="Company Name"
      type="text"
      height="40"
      dense
      solo
      flat
      rounded
      hide-details
      :disabled="isSearchDisabled"
      class="archives-search__search-input"
      @input="searchTickets"
    />
    <v-text-field
      v-model="searchFields.jobTitle"
      label="Job Title"
      type="text"
      height="40"
      dense
      solo
      flat
      rounded
      hide-details
      :disabled="isSearchDisabled"
      class="archives-search__search-input"
      @input="searchTickets"
    />
    <v-text-field
      v-model="searchFields.jobLocation"
      label="Location"
      type="text"
      height="40"
      dense
      solo
      flat
      rounded
      hide-details
      :disabled="isSearchDisabled"
      class="archives-search__search-input"
      @input="searchTickets"
    />
    <AppButton
      height="40"
      class="archives-search__search-button"
      :disabled="isSearchDisabled"
      dark
      color="var(--button-primary-color)"
      @click="searchTickets"
    >
      Search
    </AppButton>
  </div>
</template>

<script>
export default {
  name: 'ArchivesSearch',
  props: {
    isSearchDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      searchFields: {
        company: '',
        jobTitle: '',
        jobLocation: ''
      }
    }
  },
  methods: {
    searchTickets() {
      const isSearchEmpty = Object.values(this.searchFields).every(field => !field)
      if (isSearchEmpty) {
        this.$emit('searchTickets', null)
        return
      }

      this.searchFields = Object.fromEntries(
        Object.entries(this.searchFields).map(([key, value]) => [key, value.toLowerCase()])
      )
      this.$emit('searchTickets', this.searchFields)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.archives-search {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 220px) 138px;
  margin-bottom: 60px;
  gap: 21px;

  @include breakpoint-reverse(small) {
    grid-template-columns: 1fr;

    .app-button {
      width: 100%;
    }
  }

  &__search-input::v-deep {
    .v-label {
      font-size: var(--font-size-base-sm);
      color: var(--tertiary-text-color);
    }
  }

  &__search-button {
    justify-self: center;
    width: 138px;
    box-shadow: none;

    &::v-deep {
      .text {
        font-size: var(--font-size-base-sm);
        line-height: var(--line-height-xs);
        text-shadow: none;
        color: var(--primary-button-text-color);
      }

      &.v-btn.v-btn--disabled {
        background-color: var(--button-primary-color) !important;
        opacity: 0.5;
      }
    }
  }
}
</style>
