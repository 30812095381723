<template>
  <section class="archives">
    <PageHeader>
      My Archives
    </PageHeader>
    <div class="archives__content">
      <ArchivesSearch :is-search-disabled="!initialArchiveTickets.length" @searchTickets="searchTickets" />
      <v-progress-circular
        v-if="loading"
        class="loader-main"
        size="50"
        width="5"
        indeterminate
      />
      <div v-else class="archives__tickets-list">
        <template v-if="initialArchiveTickets.length">
          <ArchivesTicket
            v-for="ticket in archiveTickets"
            :key="ticket.id"
            :ticket="ticket"
            @deleteTicket="deleteTicket"
            @updateTickets="resetTickets"
          />
        </template>
        <div v-else>
          You have no archived tickets yet.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'
import ArchivesSearch from '@/components/ArchivesSearch'
import ArchivesTicket from '@/components/ArchivesTicket'

export default {
  name: 'Archives',
  components: {
    ArchivesSearch,
    ArchivesTicket
  },
  data() {
    return {
      loading: true,
      ticketId: 0,
      phase: '',
      initialArchiveTickets: [],
      archiveTickets: [],
      loadTicketsParams: {
        filterByPhase: '',
        filterByStatus: 'archived'
      }
    }
  },
  computed: {
    ...mapGetters(['getCompaniesList'])
  },
  created() {
    this.getArchivedTickets()
    if (!this.getCompaniesList.length) this.fetchCompaniesList()
  },
  methods: {
    ...mapActions(['setCompaniesList']),
    async getArchivedTickets() {
      const { data } = await api.users.getTickets(this.loadTicketsParams)

      this.loading = false

      this.initialArchiveTickets = data || []
      this.archiveTickets = this.initialArchiveTickets
    },
    async fetchCompaniesList() {
      const { data } = await api.users.getCompanies()
      if (data) this.setCompaniesList([{ name: 'Other' }, ...data])
    },
    async deleteTicket(ticketId) {
      const { error } = await api.users.deleteTicket(ticketId)

      if (!error) this.resetTickets()
    },
    resetTickets() {
      this.loading = true
      this.archiveTickets = []
      this.initialArchiveTickets = []
      this.getArchivedTickets()
    },
    searchTickets(searchValue) {
      if (!searchValue) {
        this.archiveTickets = this.initialArchiveTickets
        return
      }

      const result = this.initialArchiveTickets.filter(({ company, jobTitle, jobLocation }) => {
        return company.toLowerCase().includes(searchValue.company) &&
               jobTitle.toLowerCase().includes(searchValue.jobTitle) &&
               jobLocation.address.toLowerCase().includes(searchValue.jobLocation)
      })

      this.archiveTickets = result.length ? result : []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.archives {
  @include default-page-paddings;

  &__content {
    max-width: fit-content;
    margin: 80px auto 0;

    @include breakpoint-reverse(small) {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  &__tickets-list {
    display: grid;
    text-align: center;
    gap: 18px;
  }
}
</style>
