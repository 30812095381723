<template>
  <div class="archives-ticket">
    <div class="archives-ticket__header">
      <div class="archives-ticket__date">
        <span class="archives-ticket__date-title">
          Archived on
        </span>
        <span class="archives-ticket__date-value">
          {{ formatISOdate(ticket.date) }}
        </span>
      </div>
      <div class="archives-ticket__phase">
        <span class="archives-ticket__phase-title">
          From:
        </span>
        <span class="archives-ticket__phase-value">
          {{ ticket.currentPhase }}
        </span>
      </div>
    </div>
    <div class="archives-ticket__body">
      <div class="archives-ticket__info">
        <span class="archives-ticket__info-company">
          {{ ticket.company }}
        </span>
        <span class="archives-ticket__info-job-title">
          {{ ticket.jobTitle }}
        </span>
        <span class="archives-ticket__info-job-date">
          Ticket created on {{ formatISOdate(ticket.createDate) }}
        </span>
      </div>
      <div class="archives-ticket__actions">
        <AppButton
          outlined
          height="33"
          color="var(--button-primary-color)"
          class="archives-ticket__actions-view"
          @click="openTicketModal"
        >
          View
        </AppButton>
        <AppButton
          outlined
          height="33"
          class="archives-ticket__actions-delete"
          @click="deleteTicket"
        >
          Delete
        </AppButton>
      </div>
    </div>
    <ArchivesTicketModal
      :ticket-id="ticket.id"
      :is-archive-ticket-modal-open.sync="isArchiveTicketModalOpen"
      @updateTickets="updateTickets"
    />
  </div>
</template>

<script>
import { formatISOdate } from '@/services/dateService'
import ArchivesTicketModal from '@/components/ArchivesTicketModal'

export default {
  name: 'ArchivesTicket',
  components: {
    ArchivesTicketModal
  },
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isArchiveTicketModalOpen: false
    }
  },
  methods: {
    formatISOdate,
    openTicketModal() {
      this.isArchiveTicketModalOpen = true
    },
    deleteTicket() {
      const isConfirmed = confirm('Are you sure you want to delete this ticket?')

      if (!isConfirmed) return

      this.$emit('deleteTicket', this.ticket.id)
    },
    updateTickets() {
      this.$emit('updateTickets')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.archives-ticket {
  border-radius: var(--border-radius-default);
  max-width: 775px;
  text-align: initial;
  background-color: var(--background-secondary-color);

  &__header {
    display: flex;
    justify-content: space-between;
    border-top-left-radius: var(--border-radius-default);
    border-top-right-radius: var(--border-radius-default);
    padding: 12px 26px;
    background-color: var(--archived-ticket-bg-color);

    @include breakpoint-reverse(small) {
      padding: 12px;
    }
  }

  &__date,
  &__phase {
    font-size: var(--font-size-base-lg);

    &-title {
      margin-right: 5px;
    }

    &-value {
      font-weight: var(--font-weight-bold);
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: 21px 26px;

    @include breakpoint-reverse(small) {
      gap: 5px;
      padding: 12px;
    }
  }

  &__info,
  &__actions {
    display: grid;
    gap: 5px;
  }

  &__info-company {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-sub);
  }

  &__info-job-title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base-lg);
  }

  &__info-job-date {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-base-sm);
    color: var(--tertiary-text-color);
  }

  &__actions-view,
  &__actions-delete {
    width: 134px;

    &::v-deep {
      .text {
        font-size: var(--font-size-base-sm);
        line-height: var(--line-height-xs);
        text-shadow: none;
      }
    }
  }
}
</style>
