<template>
  <v-dialog
    v-model="isOpen"
    transition="slide-y-transition"
    max-width="638"
    content-class="archives-ticket-modal"
    @click:outside="closeModal"
  >
    <div class="header-wrapper">
      <div class="top-banner">
        <span class="modal-title">This card has been archived</span>
        <span class="modal-subtitle">You can still unarchive it.</span>
        <button class="close-button" @click="closeModal">
          <img src="/img/icons/plus-black.svg" alt="">
        </button>
      </div>
      <div class="modal-header">
        <AppSelect
          :value.sync="ticket.currentPhase"
          :items="columns"
          :error-messages="phaseError"
          is-required
          @blur="this.$v.ticket.currentPhase.$touch"
          @change="changePhase"
        />
        <AppDatePicker
          label="on:"
          :date.sync="ticket.date"
          :date-error="dateError"
          @validate="this.$v.ticket.date.$touch"
        />
        <AppAutocomplete
          :value.sync="ticket.company"
          :error-messages="companyError"
          :items="getCompaniesList"
          label="Company"
          is-required
          @change="setCompanyId"
          @blur="this.$v.ticket.company.$touch"
        />
        <AppInput
          :value.sync="ticket.jobTitle"
          :error-messages="jobTitleError"
          label="Job Title"
          type="text"
          is-required
          @blur="this.$v.ticket.jobTitle.$touch"
        />
      </div>
      <ul class="modal-breadcrumbs">
        <li
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.id"
          class="breadcrumb-item"
          :class="{ 'active': currentStep === breadcrumb.id }"
          @click="changeStepByBreadcrumb(breadcrumb.id)"
        >
          {{ breadcrumb.title }}
        </li>
      </ul>
    </div>
    <div class="modal-main">
      <transition name="slide" enter-active-class="animated-transition" :enter-class="transitionEnterClass">
        <component
          :is="activeComponent"
          :is-modal-open="isArchiveTicketModalOpen"
          :ticket.sync="ticket"
          @addNote="addNote"
        />
      </transition>
    </div>
    <div class="modal-footer">
      <div class="steps-controls">
        <AppButton
          height="34"
          outlined
          color="var(--button-primary-color)"
          class="shelve-button"
          @click="unarchiveTicket"
        >
          Unarchive card
        </AppButton>
        <div class="review-button-wrapper">
          <span class="review-button-label">Tell us more about your experience</span>
          <AppButton
            :to="{
              name: 'Reviews',
              params: { isOpenRateModal: true, ticketId: ticketId }
            }"
            height="34"
            outlined
            color="var(--background-primary-color)"
            class="review-button"
          >
            Add Review
          </AppButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import TicketModalJobInfo from '@/components/TicketModalJobInfo'
import TicketModalFilesContacts from '@/components/TicketModalFilesContacts'
import TicketModalNotesHistory from '@/components/TicketModalNotesHistory'

export default {
  name: 'ArchivesTicketModal',
  components: {
    TicketModalJobInfo,
    TicketModalFilesContacts,
    TicketModalNotesHistory
  },
  mixins: [validationMixin],
  props: {
    isArchiveTicketModalOpen: {
      type: Boolean,
      required: true
    },
    ticketId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      ticket: {},
      isOpen: false,
      isPhaseChanged: false,
      columns: [
        'Wishlist',
        'Applied',
        'Screening',
        'Interview I',
        'Interview II',
        'Accepted',
        'Offer',
        'Rejected'
      ],
      breadcrumbs: [
        { id: 1, title: 'Job Info' },
        { id: 2, title: 'Files and Contacts' },
        { id: 3, title: 'Notes and History' }
      ],
      currentStep: 1,
      transitionEnterClass: '',
      isButtonDisabled: false,
      currentPhase: '',
      ticketNote: null
    }
  },
  validations: {
    ticket: {
      currentPhase: {
        required
      },
      date: {
        required
      },
      company: {
        required
      },
      jobTitle: {
        required
      }
    }
  },
  computed: {
    ...mapGetters(['getCompaniesList']),
    activeComponent() {
      if (this.currentStep === 2) {
        return 'TicketModalFilesContacts'
      }

      if (this.currentStep === 3) {
        return 'TicketModalNotesHistory'
      }

      return 'TicketModalJobInfo'
    },
    phaseError() {
      const errors = []
      if (!this.$v.ticket.currentPhase.$dirty) return errors
      !this.$v.ticket.currentPhase.required &&
      errors.push('Phase is required')
      return errors
    },
    dateError() {
      const errors = []
      if (!this.$v.ticket.date.$dirty) return errors
      !this.$v.ticket.date.required &&
      errors.push('On date is required')
      return errors
    },
    companyError() {
      const errors = []
      if (!this.$v.ticket.company.$dirty) return errors
      !this.$v.ticket.company.required &&
      errors.push('Company is required')
      return errors
    },
    jobTitleError() {
      const errors = []
      if (!this.$v.ticket.jobTitle.$dirty) return errors
      !this.$v.ticket.jobTitle.required &&
      errors.push('Job title is required')
      return errors
    }
  },
  watch: {
    async isArchiveTicketModalOpen(value) {
      if (value) await this.getTicket()
      this.isOpen = value
    },
    currentStep(newVal, oldVal) {
      this.transitionEnterClass = newVal > oldVal ? 'slide-in-right' : 'slide-in-left'
    }
  },
  async created() {
    await this.getTicket()
    this.currentPhase = this.ticket.currentPhase
  },
  methods: {
    async getTicket() {
      const { data, error } = await api.users.getSingleTicket(this.ticketId)

      this.ticket = error ? {} : data

      if (!error) {
        this.ticket.date = this.ticket.date.substring(0, 10)
      }
    },
    changePhase() {
      this.isPhaseChanged = true
      this.ticket.date = ''
      this.validation()
    },
    closeModal() {
      this.$emit('update:isArchiveTicketModalOpen', false)
    },
    validation() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    async saveTicket() {
      if (!this.validation()) return

      this.isButtonDisabled = true

      // if we move an inactive ticket from the wishlist,
      // we make it active, since the rest of the columns do not have a status switch and the ticket will be lost
      if (this.isPhaseChanged) this.ticket.status = 'active'
      const { error } = await api.users.updateTicket(this.ticket.id, this.ticket)

      if (!error) {
        this.$emit('updateTickets')
        this.isButtonDisabled = false
        this.closeModal()

        if (this.ticketNote) this.saveNote()
      }
    },
    unarchiveTicket() {
      this.ticket.status = 'active'
      this.ticket.currentPhase = 'Wishlist'
      this.saveTicket()
    },
    changeStepByBreadcrumb(breadcrumbId) {
      if (!this.validation()) return

      this.currentStep = breadcrumbId
    },
    addNote(note) {
      this.ticketNote = note
    },
    async saveNote() {
      const { error } = await api.users.setTicketNote(this.ticketId, this.ticketNote)

      if (error) console.log(error)
    },
    setCompanyId(company) {
      if (!company) return

      this.ticket.company = company.name || company
      this.ticket.companyId = company.id || 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.v-dialog__content::v-deep .archives-ticket-modal {
  @include default-modal-wrapper;
}

.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: var(--background-modal-color);
}

.top-banner {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
  border-top-left-radius: var(--border-radius-default);
  border-top-right-radius: var(--border-radius-default);
  padding: 15px 25px 0;
  text-align: center;

  @include breakpoint-reverse(small) {
    margin-bottom: 0;
    padding: 5px 40px;
  }
}

.changed-phase-title-wrapper {
  margin-right: -24px;
  padding-bottom: 16px;
  width: 100%;
  text-align: center;
}

.modal-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h1);
  color: var(--text-color);
}

.modal-subtitle {
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-sm);
}

.close-button {
  position: absolute;
  right: 15px;
  top: 11px;
  width: 24px;
  height: 24px;
  transform: rotate(45deg);
}

.modal-header {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding: 2px 25px 30px;

  @include breakpoint-reverse(small) {
    padding: 5px 12px;
  }
}

@include default-modal-breadcrumbs;

.modal-main {
  @include default-modal-main;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  padding: 37px 25px;
  background-color: var(--background-modal-color);

  @include breakpoint-reverse(small) {
    padding: 12px;
  }

  .steps-controls {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;

    @include breakpoint-reverse(small) {
      grid-template-columns: 1fr;
      text-align: center;

      & a.app-button, div, button.app-button {
        width: 100%;
      }
    }
  }

  .save-button,
  .archive-button,
  .review-button,
  .shelve-button {
    &::v-deep .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-base-lg);
      line-height: var(--line-height-xs);
      text-shadow: none;
    }

    &.app-button {
      width: 220px;
    }
  }

  .review-button-wrapper,
  .save-button {
    margin-left: auto;

    &::v-deep .text {
      font-weight: var(--font-weight-bold);
    }
  }

  .review-button-wrapper {
    display: grid;
    margin-top: -18px;
  }

  .review-button-label {
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-sm);
  }
}
</style>
